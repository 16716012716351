<template>
  <div
    class="tooltip"
    @mouseover="setClassName"
  >
    <div class="tooltip__item image-item">
      <nuxt-img
        :src="String(colorItem.imageId)"
        :width="40"
        :height="40"
        fit="cover"
        loading="lazy"
        :alt="`color-${colorItem.imageId}`"
      />
    </div>
    <div
      ref="refHover"
      class="tooltip__hover"
      :class="className"
    >
      <span v-html="colorItem.value"/>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  colorItem: {
    type: Object,
    default: () => {
      return {}
    }
  }
});

const refHover: any = ref(null);
const className: Ref<string> = ref('');
const setClassName = () => {
  const boundingClientRect = refHover.value.getBoundingClientRect();
  if (boundingClientRect?.left >= 0) {
    return
  }

  className.value = "--right";
};
</script>

<style scoped lang="scss">
.tooltip__hover.--right {
  right: initial;
  left: 50%;
  border-radius: 15px 15px 15px 0;

  &:after {
    right: initial; left: 0;
    border-right: initial;
    border-left: 15px solid #F4F3F1;
  }
}
</style>
